import Lottie from "lottie-react";

import { forwardRef } from "react";
import LoadingThreeDotLottie from "../Lottie/3-dot-loading.json";
import AngryAnimationLottie from "../Lottie/angry-animation.json";
import SuccessSimpleLottie from "../Lottie/check-done.json";
import CheckUserLottie from "../Lottie/check-user.json";
import DocumentProcessingLottie from "../Lottie/document-processing.json";
import ErrorGirlWithLaptopLottie from "../Lottie/error-girl-2.json";
import ErrorPumpkinLottie from "../Lottie/error-pumpkin.json";
import ErrorTriangleLottie from "../Lottie/error-triangle.json";
import FailedSadLottie from "../Lottie/failed-sad.json";
import HomePageAnimationLottie from "../Lottie/home-page.json";
import LoadingDocumentRowLottie from "../Lottie/loading-document-row.json";
import LoadingHandLottie from "../Lottie/loading-hand.json";
import GlassHourLottie from "../Lottie/loading_glass_hour.json";
import NotFound404Lottie from "../Lottie/not-found-404.json";
import PaperPlaneSendingLottie from "../Lottie/paper_plane_sending.json";
import SaveDiscketLottie from "../Lottie/save-discket.json";
import SendLoadingLottie from "../Lottie/send-loading.json";

const AngryAnimation = forwardRef(({ loop = true, autoPlay = true, ...other }, ref) => {
  return <Lottie ref={ref} animationData={AngryAnimationLottie} loop={loop} autoPlay={autoPlay} {...other} />;
});

const CheckUser = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={CheckUserLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const DocumentProcessing = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={DocumentProcessingLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const ErrorGirlWithLaptop = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={ErrorGirlWithLaptopLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const ErrorPumpkin = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={ErrorPumpkinLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const ErrorTriangle = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={ErrorTriangleLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const FailedSadAnimation = forwardRef(({ loop = true, autoPlay = true, ...other }, ref) => {
  return <Lottie ref={ref} animationData={FailedSadLottie} loop={loop} autoPlay={autoPlay} {...other} />;
});

const GlassHour = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={GlassHourLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const HomePageAnimation = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={HomePageAnimationLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const LoadingDocumentRow = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={LoadingDocumentRowLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const LoadingHand = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={LoadingHandLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const LoadingThreeDot = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={LoadingThreeDotLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const NotFound404 = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={NotFound404Lottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const PaperPlaneSending = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={PaperPlaneSendingLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const SaveDiscket = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={SaveDiscketLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const SendLoading = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={SendLoadingLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

const SuccessSimple = ({ loop = true, autoPlay = true, ...other }) => {
  return <Lottie animationData={SuccessSimpleLottie} loop={loop} autoPlay={autoPlay} {...other} />;
};

export {
  AngryAnimation,
  CheckUser,
  DocumentProcessing,
  ErrorGirlWithLaptop,
  ErrorPumpkin,
  ErrorTriangle,
  FailedSadAnimation,
  GlassHour,
  HomePageAnimation,
  LoadingDocumentRow,
  LoadingHand,
  LoadingThreeDot,
  NotFound404,
  PaperPlaneSending,
  SaveDiscket,
  SendLoading,
  SuccessSimple,
};
