const STATUS_SO_ENUM = {
  NEW: "NEW",
  PROCESSED: "PROCESSED",
  PARTLY_DELIVERED: "PARTLY DELIVERED",
  DELIVERED: "DELIVERED",
  CANCEL: "CANCEL",
  DELETED: "DELETED",
  REJECTED_ORDER: "REJECTED ORDER",
  PARTLY_CANCEL: "PARTLY CANCEL",
};

const DOCUMENT_FLOW_COLORS_STATUS = {
  OPEN: "#3b83f6",
  WAITING: "#9e9e9e",
  CLOSE: "#2e7d32",
  RETURN: "#ed6c02",
  REJECT: "#d32f2f",
};

const DOCUMENT_COLORS_STATUS = {
  NEW: "#2daae7",
  OPEN: "#009688",
  ON_PROCESS: "#4caf50",
  CLOSED: "#2979ff",
  DELETED: "#ff1744",
  RETURNED: DOCUMENT_FLOW_COLORS_STATUS.RETURN,
  REJECTED: DOCUMENT_FLOW_COLORS_STATUS.REJECT,
};

const ENUM_STATUS_TTD_STATUS_COLOR = {
  DELETED: "#ff1744",
  REJECTED: "#ff1744",
  RETURNED: "#ed6c02",
  NEW: "#2daae7",
  OPEN: "#009688",
  "PARTLY USED": "#009688",
  CLOSE: "#2e7d32",
};

const DOCUMENT_COLORS_STATUS_DSO = {
  OPEN: "primary",
  CLOSED: "success",
  DELETED: "error",
};

const MODULE_CODES = {
  INVOICE: "FP",
  PELUNASAN_FAKTUR: "PF",
  RETUR: "BS",
  SALES_ORDER: "SO",
  SURAT_JALAN: "SJ",
  TANDA_TERIMA: "TT",
  MASTER_MATERIAL: "MAT",
};

const ENUM_COLOR_STATUS_PR = {
  NEW: "info",
  OPEN: "primary",
  CLOSED: "success",
  RETURNED: "warning",
  REJECTED: "error",
  DELETED: "error",
};

const ENUM_COLOR_STATUS_PO = {
  OPEN: "primary",
  CLOSED: "success",
  CANCEL: "warning",
  DELETED: "error",
};

const ENUM_COLOR_STATUS_INVR_PO = {
  UNPAID: "primary",
  "PARTLY PAID": "primary",
  PAID: "success",
  DELETED: "error",
};

const PRINT_PAPER_SETTINGS = {
  BESAR: {
    height: 279.4,
  },
  KECIL: {
    height: 139.7,
  },
  measure: "mm",
  padding: {
    top: 10,
    right: 9,
    bottom: 10,
    left: 8,
  },
};

const ENUM_M_PROMO_TYPE = Object.freeze({
  "Promo Pabrik": "Promo Pabrik",
  "Paket Diskon": "Paket Diskon",
});

const ENUM_JENIS_PROMO_PAKET_DISKON = Object.freeze({
  "Persentase Diskon": "Persentase Diskon",
  "Gratis Produk": "Gratis Produk",
  "Nominal Diskon": "Nominal Diskon",
});

export {
  DOCUMENT_COLORS_STATUS,
  DOCUMENT_COLORS_STATUS_DSO,
  DOCUMENT_FLOW_COLORS_STATUS,
  ENUM_COLOR_STATUS_INVR_PO,
  ENUM_COLOR_STATUS_PO,
  ENUM_COLOR_STATUS_PR,
  ENUM_JENIS_PROMO_PAKET_DISKON,
  ENUM_M_PROMO_TYPE,
  ENUM_STATUS_TTD_STATUS_COLOR,
  MODULE_CODES,
  PRINT_PAPER_SETTINGS,
  STATUS_SO_ENUM,
};
