// const protocol = window.location.protocol;
// const hostname = window.location.hostname;
// const portAPI = '3001';
// const PATH = `${protocol}/${hostname}:${portAPI}`;

/* DEV */
const ORIGIN = process.env.REACT_APP_API_HOSTNAME || `${window.location.protocol}//${window.location.hostname}:3001`;
// const ORIGIN = process.env.REACT_APP_API_HOSTNAME;

/* STAGING */
// const ORIGIN = 'https://staging.api.liondiamondplastics.com';

/* PRO */
// const ORIGIN = 'https://api.liondiamondplastics.com';

/* V2 */
// const ORIGIN = 'https://v1.liondiamondplastic.online';

const URL_LOGIN = `/Login`;
const URL_MASTER_CUSTOMER = `/mastercustomer`;
const URL_MASTER_EXPEDITION = `/masterexpedition`;
const URL_MASTER_ITEM = `/masteritem`;
const URL_MASTER_CITY = `/mastercity`;
const URL_MASTER_MENU = `/mastermenu`;
const URL_MASTER_SUPPLIER = `/supplier`;
const URL_SALES_ORDER = `/salesorder`;
const URL_SURAT_JALAN = `/suratjalan`;
const URL_FAKTUR = `/faktur`;
const URL_FAKTUR_DEPOSIT_ORDER = `/FP-DO`;
const URL_REKENING = `/masterrekening`;
const URL_BS = `/bs`;
const URL_RECEIPT = `/receipt`;
const URL_GL_ACCOUNTS = `/masterglaccount`;
const URL_GL_UPLOADS = `${ORIGIN}/upload`;
const URL_PELUNASAN_FAKTUR = `/PF`;
const URL_DO = `/DO`;
const URL_TTD = `/TTD`;
const URL_REPORT = `/report`;
const URL_DSO = `/DSO`;
const URL_REFUND = `/REFUND`;
const URL_MASTER_MATERIAL = `/MAT`;
const URL_PURCHASE_REQUISITION = `/PR`;
const URL_PURCHASE_ORDER = "/PO";
const URL_GOODS_RECEIPT_PO = "/GR-PO";
const URL_INVOICE_RECEIPT_PO = "/INVR-PO";
const URL_INVOICE_PAYMENT_PO = "/INVP-PO";
const URL_ACCURATE = "/ACCURATE";
const URL_MASTER_SELLER = "/SELLER";
const URL_PROMO = "/PROMO";

const APP_BAR_TITLE = "Lion Diamond";

export {
  APP_BAR_TITLE,
  ORIGIN,
  URL_ACCURATE,
  URL_BS,
  URL_DO,
  URL_DSO,
  URL_FAKTUR,
  URL_FAKTUR_DEPOSIT_ORDER,
  URL_GL_ACCOUNTS,
  URL_GL_UPLOADS,
  URL_GOODS_RECEIPT_PO,
  URL_INVOICE_PAYMENT_PO,
  URL_INVOICE_RECEIPT_PO,
  URL_LOGIN,
  URL_MASTER_CITY,
  URL_MASTER_CUSTOMER,
  URL_MASTER_EXPEDITION,
  URL_MASTER_ITEM,
  URL_MASTER_MATERIAL,
  URL_MASTER_MENU,
  URL_MASTER_SELLER,
  URL_MASTER_SUPPLIER,
  URL_PELUNASAN_FAKTUR,
  URL_PROMO,
  URL_PURCHASE_ORDER,
  URL_PURCHASE_REQUISITION,
  URL_RECEIPT,
  URL_REFUND,
  URL_REKENING,
  URL_REPORT,
  URL_SALES_ORDER,
  URL_SURAT_JALAN,
  URL_TTD,
};
